import React from 'react'
import styled from 'styled-components'
import SearchIcon from '../images/Search.svg'
import {
  MOBILE_ONLY_CSS_WRAPPER
} from '../components/common'

const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 13px;

  background: #FFFFFF;
  border: 1px solid #005564;
  /* Form Box Shadow */

  box-shadow: 0px 2px 8px rgba(79, 119, 169, 0.12);
  border-radius: 9px;
  ${MOBILE_ONLY_CSS_WRAPPER('padding: 5px 13px;')}
`
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ImageContainer = styled.img`
  margin: auto; 
`

const InputText = styled.input`

  width: 100%;
  border-style: none;
  &:focus {
    outline: none;
  }
  font-family: Arial;
  font-size: 18px;
  padding-top: 4px;
    :focus::-webkit-input-placeholder {
      padding-top: 4px;
    }
    ::-webkit-input-placeholder {
      padding-top: 4px;
    }

`

const SearchField = ( { onChange, style }) => {
  return (
    <SearchBox style={style}>
      <IconContainer><ImageContainer src={SearchIcon} width="20" height="20" /></IconContainer>
      <InputText style={{ paddingLeft: '8px'}} type="string" placeholder="Find your bank" onChange={onChange}/>
    </SearchBox>
  )
}

export default SearchField
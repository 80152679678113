import React from "react"
import styled from 'styled-components'
import { useQuery } from 'react-query'
import {
  Buttons, Flex,
  GreenBarSection, HeaderBanner, LightBlueSection,
  Text, WhiteSection, Vis, MOBILE_ONLY_CSS_WRAPPER,
  TABLET_ONLY_CSS_WRAPPER,
  ButtonLinkOutline,
  ButtonGreen
} from '../components/common'
import Layout from "../components/layout"
import { navTo } from '../utils/navigation'
import SearchField from '../components/SearchField'
import RoundCircle from '../images/Tick_inactive_30.svg'
import CheckCircle from '../images/Tick_active_30.svg'
import api from '../utils/api'
import { Breadcrumb } from "../components/Breadcrumb"
import { IS_PROD } from "../environment"

const BankHolderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 16px 1px 1px;
  background: #FFFFFF;
  border: 1px solid #373737;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 10px 0px;
  min-height: 48px;
  cursor: pointer;
`
const BankLogo = styled.img`
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius:16px;
  margin:9px;
  object-fit: contain;
  border: 1px solid #005564;
  flex-shrink: 0;
`

const Checkbox = styled.img`
  height:25px;
  width:25px;
  margin-right: 5px;
`


const sortByName = (a, b) => {
  if (a.brandName.toLowerCase() < b.brandName.toLowerCase()) {
    return -1
  }
  if (a.brandName.toLowerCase() > b.brandName.toLowerCase()) {
    return 1
  }
  return 0
}

const CustomScrollDiv = styled(Flex)`
flex-direction: column;
overflow-y: auto;

&::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #016E80;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  // background: #555;
}
// adding box shadow to the bottom of the scroll
&:after {
  content: '';
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  line-height: 16px;
  box-shadow: 1px 1px 10px 10px #F5FAFF;
}
`

// outter container set to relative so the Child can have an absolute position
const RelativeFlexMobile = styled(Flex)`
flex-direction: column;
position: relative;
`
//  This is the work around for iOS fullscreen issue
const MobileFullPageAbsoluteDiv = styled.div`
display:flex;
flex-direction:column;
top:0;
right:0;
left:0;
bottom:0;
position:absolute;
`

const ErrorBootstrapSection = styled.div`
  margin: 20px 18px;
  ${TABLET_ONLY_CSS_WRAPPER('margin: 20px 16%')}
`


const SelectBank = "Select your bank"
const YourDataCan = "Your data can only be collected from one bank at a time"


const ButtonDelay = (navigateFn, payload) => {
  setTimeout(() => {
    if (payload) {
      navigateFn(payload)
    } else {
      navigateFn()
    }
  }, 300)
}

const SelectYourBankPage = (props) => {

  const userDetail = api.useGetProMember()

  const MATCH_ALL = new RegExp('.*')
  const [filter, setFilter] = React.useState('')
  const [regexFilter, setRegexFilter] = React.useState(MATCH_ALL)
  const [selectedBank, setSelectedBank] = React.useState(null)
  const [disableNext, setDisableNext] = React.useState(false) // in case the dataholder calll didnt call
  const [limitMessage, setLimitMessage] = React.useState(null)
  const { location: { state = {} } = {} } = props
  const [errorMessage, setErrorMessage] = React.useState(null)

  let { isLoading: userIsLoading, data: userData = null, isSuccess: userIsSuccess } = useQuery('users', async () => {
    return new Promise((resolve, reject) => {
      api.getUserDetail().then((returnData) => resolve(returnData.data)).catch(err => reject(err))
    })
  }, {
    retry: 1,
    refetchOnWindowFocus: false
  })

  const { data: { holders: banksList } = {} } = useQuery('dataholders', async () => {
    const { data } = await api.getDataHolders()
    return data
  }, {
    refetchOnWindowFocus: false,
    staleTime: 1 * 60 * 1000, // 1mins
    placeholderData: require('./placeholder-bank-list.json'),
    onSuccess: (result) => {
      setErrorMessage(null)
      if (result.limit) {
        if (result.limit.allow) {
          setDisableNext(false)
        } else {
          setDisableNext(true)
          setLimitMessage(result.limit.errorMsg)
        }
      }
    },
    onError: (error) => {
      console.error("Unable to fetch data holder", error)
      setErrorMessage("Sorry, we are having trouble getting a list of available banks. Please try again later.")
    }
  })


  const updateFilter = e => {
    const val = e.target.value
    setFilter(val)
    setRegexFilter(val.length < 2 ? MATCH_ALL : new RegExp(val, 'i'))
  }


  const bankUri = selectedBank?.logoUri
  const ButtonGroup = () => {
    const backButtonFn = () => userDetail.data?.isPro ? ButtonDelay(navTo.configureyourconsent_2, { ...state }) : ButtonDelay(navTo.letsgetyouverified_3, { ...state })
    return (
      <Flex flex="row">
        {
          limitMessage &&
          <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px', marginTop: '-60px' }}>
            <div className="alert alert-danger">{limitMessage.error}</div>
          </ErrorBootstrapSection>
        }
        {/* <Buttons.BackNextButtons
          backOnClick={() => backButtonFn()}
          nextOnClick={() => ButtonDelay(navTo.selectyourdata_6, { ...state, bank: selectedBank, isCommercial: userDetail.data?.isPro })}
          nextDisabled={disableNext || selectedBank === null}
        /> */}
        <ButtonLinkOutline
          style={{ padding: "0 30px", marginRight: '21px', minWidth: "50%" }}
          rel="noreferrer noopener"
          onClick={() => backButtonFn()}>
          Back
        </ButtonLinkOutline>
        <ButtonGreen
          style={{ padding: "0 30px", minWidth: "50%" }}
          onClick={() => ButtonDelay(navTo.selectyourdata_6, { ...state, bank: selectedBank, isCommercial: userDetail.data?.isPro })}
          disabled={disableNext || selectedBank === null}>
          Next
        </ButtonGreen>
      </Flex>

    )
  }


  return (
    <Layout.ColumnLayout fitToHeightOnMobile={true} title={SelectBank}>
      <HeaderBanner flex="nogrow" logoUrl={bankUri} withStep={true} hamburger={false} commercial={userIsSuccess} commercialProperties={userData} userIsLoading={userIsLoading}>
        <Breadcrumb from={1} to={6} />
      </HeaderBanner>
      {/* mobile only */}

      <Vis.MobileOnly flex="col">
        <RelativeFlexMobile>
          <MobileFullPageAbsoluteDiv>
            <WhiteSection flex="col nogrow" style={{ margin: '11px 10px 0px 10px' }}>
              <Vis.MobileOnly><Text.Steps>Step 1 of 6</Text.Steps></Vis.MobileOnly>
              <Text.H1 style={{ marginTop: '0px', marginBottom: '16px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{SelectBank}</Text.H1>
              <GreenBarSection style={{ marginBottom: '21px', color: '#373737', fontFamily: 'Arial' }}>{YourDataCan}</GreenBarSection>
            </WhiteSection>

            {/* banks */}
            <LightBlueSection flex="col" style={{ margin: '0px', padding: '25px 28px', overflowY: 'auto' }}>
              <SearchField style={{ marginBottom: '5px' }} value={filter} onChange={updateFilter} />
              <CustomScrollDiv>
                {banksList && banksList.sort(sortByName).filter(bank => regexFilter.test(bank.brandName)).map((bank, inx) => (
                  <BankHolderBox key={inx} onClick={_ => setSelectedBank(bank)}>
                    <Flex flex="vcenter" style={{ fontSize: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <BankLogo alt='' src={bank.logoUri} aria-label={bank.brandName} />
                      <span style={{ paddingTop: '4px' }}>{bank.brandName}</span>
                    </Flex>
                    <Checkbox src={selectedBank === bank ? CheckCircle : RoundCircle} />
                  </BankHolderBox>
                ))}
              </CustomScrollDiv>
              {
                errorMessage &&
                <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px' }}>
                  <div className="alert alert-danger">{errorMessage}</div>
                </ErrorBootstrapSection>
              }
            </LightBlueSection>
            <WhiteSection flex="row nogrow" style={{ marginTop: '20px' }}>
              <ButtonGroup />
            </WhiteSection>
          </MobileFullPageAbsoluteDiv>
        </RelativeFlexMobile>
      </Vis.MobileOnly>

      <Vis.TabletOnly flex="col">
        <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
          <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{SelectBank}</Text.H1>
          <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>{YourDataCan}</GreenBarSection>
        </WhiteSection>
        {/* banks */}
        <LightBlueSection flex="col" style={{ margin: '0px 60px', padding: '25px 20px' }}>
          <SearchField style={{ marginBottom: '5px' }} value={filter} onChange={updateFilter} />
          <CustomScrollDiv style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {banksList && banksList.sort(sortByName).filter(bank => regexFilter.test(bank.brandName)).map((bank, inx) => (
              <BankHolderBox key={inx} onClick={_ => setSelectedBank(bank)}>
                <Flex flex="vcenter" style={{ fontSize: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <BankLogo alt='' src={bank.logoUri} aria-label={bank.brandName} />
                  <span style={{ paddingTop: '4px' }}>{bank.brandName}</span>
                </Flex>
                <Checkbox src={selectedBank === bank ? CheckCircle : RoundCircle} />
              </BankHolderBox>
            ))}
          </CustomScrollDiv>
          {
            errorMessage &&
            <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px' }}>
              <div className="alert alert-danger">{errorMessage}</div>
            </ErrorBootstrapSection>
          }
        </LightBlueSection>

        <WhiteSection flex="row nogrow" style={{ margin: '40px 0px' }}>
          <ButtonGroup />
        </WhiteSection>
      </Vis.TabletOnly>

      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col w50" style={{ padding: '0px' }}>
            <WhiteSection flex="col nogrow">
              <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{SelectBank}</Text.H1>
              <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>{YourDataCan}</GreenBarSection>
            </WhiteSection>
            <WhiteSection flex="row" style={{ marginTop: "90px", padding: "0px 0px", width: '424px' }}>
              <ButtonGroup />
            </WhiteSection>
          </WhiteSection>
          <Flex flex="w50 col" style={{ paddingTop: '35px' }}>
            <LightBlueSection flex="col nogrow" style={{ margin: '0px 40px', padding: '25px 50px' }}>
              <SearchField style={{ marginBottom: '5px' }} value={filter} onChange={updateFilter} />
              <CustomScrollDiv style={{ maxHeight: '350px', overflowY: 'auto' }}>
                {banksList && banksList.sort(sortByName).filter(bank => regexFilter.test(bank.brandName)).map((bank, inx) => (
                  <BankHolderBox key={inx} onClick={_ => setSelectedBank(bank)}>
                    <Flex flex="vcenter" style={{ fontSize: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <BankLogo alt='' src={bank.logoUri} aria-label={bank.brandName} />
                      <span style={{ paddingTop: '4px' }}>{bank.brandName}</span>
                    </Flex>
                    <Checkbox src={selectedBank === bank ? CheckCircle : RoundCircle} />
                  </BankHolderBox>
                ))}
              </CustomScrollDiv>
              {
                errorMessage &&
                <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px' }}>
                  <div className="alert alert-danger">{errorMessage}</div>
                </ErrorBootstrapSection>
              }
            </LightBlueSection>
          </Flex>
        </WhiteSection>
        {/* {
          isCommercial && 
          <WhiteSection flex="row" style={{ margin: '0px 50px 0px 100px' }}>
            <pre style={{ padding: '10px 10px', backgroundColor: 'lightgrey' }}>Duration: {durationSec}, Pushed Authroisation Request:{JSON.stringify(par)}</pre>
          </WhiteSection>
        } */}
      </Vis.DesktopOnly>

    </Layout.ColumnLayout>
  )
}

export default SelectYourBankPage
